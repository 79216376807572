import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import config from "../../config";

const CourseDetail = () => {
  const { state } = useLocation();
  const [colleges, setColleges] = useState([]);
  const [error, setError] = useState(null);

  const course = state?.course;

  useEffect(() => {
    if (course) {
      fetch(`${config.API_URL}/api/colleges?course=${course.id}`)
        .then((response) => response.json())
        .then((data) => setColleges(data))
        .catch((error) => {
          console.error("Failed to load colleges", error);
          setError("Failed to fetch colleges.");
        });
    }
  }, [course]);

  return (
    <div className="relative min-h-screen bg-gray-100 text-black">
      {/* Background Image Section */}
      <div
        className="relative bg-cover bg-center bg-no-repeat h-[60vh]"
        style={{
          backgroundImage: `url(${config.API_URL}${course?.image || ""})`,
        }}
      >
        {/* Dark overlay for contrast */}
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>

        {/* Course Name at the bottom left of the image */}
        <div className="absolute bottom-4 left-4 text-white font-bold text-4xl z-10">
          {course?.name || "Course Name"}
        </div>
      </div>

      {/* Description Section (below the image) */}
      <div className="mt-4 bg-white p-6 shadow-md rounded-lg max-w-screen-lg mx-auto">
        <p className="text-lg md:text-xl leading-relaxed text-black">
          {course?.description || "No description available."}
        </p>
      </div>

      {/* Colleges Scroll Section */}
      <div className="relative mt-8">
        {error && <div className="text-red-400 text-center mb-4">{error}</div>}

        <div className="overflow-hidden bg-gray-100 py-4">
          <div className="flex animate-college-scroll">
            {colleges.concat(colleges).map((college, index) => (
              <div
                key={`${college.id}-${index}`}
                className="min-w-[180px] h-48 bg-white text-gray-800 rounded-lg shadow-md overflow-hidden flex flex-col items-center"
              >
                <img
                  src={`${config.API_URL}${college.image}`}
                  alt={college.name}
                  className="w-full h-3/4 object-cover rounded-t-lg"
                />
                <p className="text-sm text-center mt-2 px-2">{college.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Tailwind Scroll Animation Keyframe */}
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        .animate-college-scroll {
          animation: scroll 20s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default CourseDetail;
